<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <div class="bg-block p-4">
          <h4>{{ individualProfile.formal_name }}</h4>
          <h1 class="sm mb-4">{{ translations.tcEditPreferredEmail }}</h1>
          <div class="body">
            <b-form>
              <b-form-group class="form-element">
                <div class="g-row">
                  <b-row>
                    <b-col sm="6">
                      <b-form-group class="form-element">
                        <h3 class="flex-2 mr-3 text-left font-style-2">
                          {{ translations.tcPreferredEmail }}
                        </h3>
                        <b-form-select v-model="selectedEmail" :options="optionsEmails"
                          class="form-control g-select flex-0 mr-3 mb-3">
                          <template slot="first"></template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col sm="6">
                      <b-button class="mt-4" variant="primary" @click="emailStoreAndRedirect({})">
                        {{ translations.tcAddEmail }}
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-form-group>
            </b-form>
          </div>
          <div class="body">
            <div class="d-flex">
              <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
                  translations.tcSave
              }}</b-button>
              <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
                  translations.tcCancel
              }}</b-button>
            </div>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'edit-member-preferred-email',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      selectedEmail: null,
    }
  },
  methods: {
    ...mapActions({
      getIndividualCommunications: 'membership/getIndividualCommunications',
      getIndividualCommPreferences: 'membership/getIndividualCommPreferences',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedEmail: 'user/setSelectedEmail',
      saveEmail: 'membership/saveEmail',
    }),
    async pageLoad() {
      this.setLoadingStatus(true)
      Promise.all([this.getViewTranslations(), this.getIndividualCommPreferences(this.userSelectedIndividualKey)]).then(
        () => {
          this.selectedEmail = this.individualCommunicationPreferencesData.memberEmails.find(
            (e) => e.primary_email_flag === 1
          ).eml_key
          this.setLoadingStatus(false)
        }
      )
    },
    async handleSaveClick() {
      if (this.selectedEmail === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcEmailRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        let newPrefferedEmail = this.individualCommunicationPreferencesData.memberEmails.find(
          (e) => e.eml_key === this.selectedEmail
        )

        var payload = {
          member_no: this.demograph.member_number,
          // email: {
          eml_key: newPrefferedEmail.eml_key,
          eml_entity_key: newPrefferedEmail.email_entity_key,
          eml_emt_key: newPrefferedEmail.emt_key,
          eml_invalid_flag: newPrefferedEmail.email_invalid_flag,
          eml_unlisted_flag: newPrefferedEmail.email_unlisted,
          eml_address: newPrefferedEmail.email_address,
          eml_delete_flag: false,
          eml_user: this.userIndKey,
          // },
          primary_email_flag: true,
          allow_confirm_primary_procedure_run: true,
        }

        let result = true
        await Promise.all([
          this.setLoadingStatus(true),
          (result = await this.saveEmail(payload)),
          this.getIndividualCommPreferences(this.userSelectedIndividualKey),
        ]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcEmailSaved : this.translations.tcErrorEmailNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.handleCancelClick()
            }
          })
        })
      }
    },
    async emailStoreAndRedirect(data) {
      this.setSelectedEmail(data)
      this.$router.push({ name: 'add-member-email' })
    },
    async handleCancelClick() {
      this.$router.push({ name: 'user-preferences' })
    },
  },
  computed: {
    ...mapGetters({
      userIndKey: 'user/userId',
      userName: 'user/userName',
      demograph: 'user/demograph',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userSelectedEmail: 'user/userSelectedEmail',
      individualProfile: 'membership/individualProfile',
      individualCommunicationPreferencesData: 'membership/individualCommunicationPreferencesData',
      prefCulture: 'user/userPreferredCulture',
    }),
    optionsEmails() {
      return [
        ...this.individualCommunicationPreferencesData.memberEmails.map((e) => {
          return {
            text: e.email_address,
            value: e.eml_key,
          }
        }),
      ]
    },
  },
  async created() {
    if (!this.userSelectedIndividualKey) {
      this.$router.push('/programs/mb/membership/')
    }
    await this.pageLoad()
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    iTooltip: iTooltip,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

.page-body {
  padding: 80px 15px;
}
</style>
